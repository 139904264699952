import React, { Component } from 'react';
import Axios from 'axios';
import PortalConfig from '../../config';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Logo from '../../images/logo.png';
import Alert from '../Alerts/Alert';

class Login extends Component
{
    state = {
        user_name: '',
        password: '',
        loading: false,
        loading_message: '',
        error_message: '',
        uploaded_logo: 'https://portal.kiyocrmondemand.com/logo.png',
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.state.user_name || !this.state.password) {
            this.setState({
                error: true,
                error_message: 'Enter username and password',
                loading: false,
            });
            return false;
        }

        this.setState({ loading: true });

        const post_data = {
            user_name: this.state.user_name,
            password: this.state.password,
        };

        Axios.post(PortalConfig.crm_url + '/auth/token', post_data).then((response) => {
            if(response.data.status === true && response.data.token) {
                // Store the token information in cookie and set logged in status to true
                Cookies.set('ba_customer_portal_token', response.data.token);
                Cookies.set('ba_customer_portal_information', JSON.stringify(response.data));
                this.setState({
                    loading: false,
                });

                this.props.updateLoginStatus(true);
            }
            else {
                this.setState({
                    error: true,
                    error_message: 'Something went wrong!',
                    loading: false,
                });
            }
        })
        .catch((error) => {
            var error_message;
            if(error.response) {
                error_message = error.response.data.message;
            }
            else {
                error_message = error.message 
            }
            
            // Set error status and error message
            this.setState({
                error: true,
                error_message: error_message,
                loading: false,
            });
        });
    }

    hideAlert() {
        this.setState({
            error: false,
            error_message: '',
        }); 
    }

    componentWillMount() {
        document.body.classList.toggle('login-body');
    }

    componentWillUnmount() {
        document.body.classList.toggle('login-body');
    }

    showForgetPasswordForm(event) {
        event.preventDefault();
    }

    render() {

        if(this.props.logged_in === true) {
            return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
        }

        let signin_button;
        if(this.state.loading === false) {
            signin_button = <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" onClick={this.handleSubmit}>Sign in</button>
        }
        else {
            signin_button = <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" disabled> Signing in... </button>
        }

        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                {this.state.error === true ? <div className="my-4 mx-auto"> <Alert hideAlert={this.hideAlert} message={this.state.error_message} type="danger" /> </div> : "" }
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={this.state.uploaded_logo ? this.state.uploaded_logo : Logo}
                        alt="Logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" method="POST">
                            <div>
                                <label htmlFor="user_name" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="user_name"
                                        name="user_name"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        autoFocus
                                        value={this.state.user_name} 
                                        onChange={this.handleChange}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        value={this.state.password} 
                                        onChange={this.handleChange}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-between flex-row-reverse">
                                <div className="text-sm">
                                    <Link to={`${process.env.PUBLIC_URL}/forget-password`} className="font-medium text-skin-primary hover:text-skin-primary-darker">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>
                            <div>
                                {signin_button}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
