import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../../Date.css';
import { parseISO } from 'date-fns';
import Pristine from "pristinejs";
import Axios from "axios";
import Cookies from "js-cookie";
import PortalConfig from "../../../../config";

const defaultConfig = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-red-500 text-xs'
};

function AdvancedSearch(props) {
    const [errorMessage, setErrorMessage] = useState('');
    const [filterLoading, setFilterLoading] = useState(false);
    const [advancedSearch, setAdvancedSearch] = useState([]);
    const [advancedGroupSearch, setAdvancedGroupSearch] = useState([[{
        field_name: '',
        field_condition: 'equal',
        field_value: '',
    }]]);
    const [groupCondtionCount, setgroupCondtionCount] = useState(['AND']);
   

    useEffect(() => {
   //     setAdvancedGroupSearch(props.advancedSearch);
    //    setgroupCondtionCount(props.groupCondtionCount);
    //    setAdvancedSearch(props.advancedSearch);
    }, [props.advancedSearch]);

    const conditions = {
        equal: 'equal',
        not_equal: 'not equal',
        contains: 'contains',
        greater_than: 'greater than',
        less_than: 'less than',
        not_empty: 'is not empty',
        empty: 'empty',
    };

    const conditionOperator = {
        AND: 'AND', OR: 'OR'
    };

    function handleGroupChange(event) {
        const group = event.target.getAttribute('data-id');
        var newGroup = Object.assign([], groupCondtionCount);
        const value = event.target.value;
        newGroup[group] = value;
        setgroupCondtionCount(newGroup);
    }

    // Handle search input change
    function handleChange(event) {
        const value = event.target.value;
        const id = event.target.getAttribute('data-id');
        const name = event.target.getAttribute('data-name');
        const group = event.target.getAttribute('data-group_id');
        let newState = Object.assign([], advancedGroupSearch);
        newState[group][id][name] = value;
        setAdvancedGroupSearch(newState);
    }

    // Handle date change
    function handleDateChange(value, id, group) {
        let newState = Object.assign([], advancedGroupSearch);
        newState[group][id]['field_value'] = value;
        setAdvancedGroupSearch(newState);
    }

    // Add new condition in the search
    function addNewCondition(index) {
        let newState = Object.assign([], advancedGroupSearch);
        newState[index].push({
            field_name: '',
            field_condition: 'equal',
            field_value: '',
        });
        setAdvancedGroupSearch(newState);
    }
    function addNewGroup() {
        var newGroup = Object.assign([], groupCondtionCount);
        var newGroupCondition = Object.assign([], advancedGroupSearch);
        var field = [{
            field_name: '',
            field_condition: 'equal',
            field_value: '',
        }];
        newGroupCondition.push(field);
        newGroup.push('AND');
        setgroupCondtionCount(newGroup);
        setAdvancedGroupSearch(newGroupCondition)

    }

    // Delete condition
    function deleteCondition(index, groupIndex) {
        let confirmation = window.confirm('Are you sure you want to delete this condition?');
        if (confirmation) {
            let newState = Object.assign([], advancedGroupSearch);
            delete newState[groupIndex][index];
            setAdvancedGroupSearch(newState);
        }
    }

    function onDateTimeChange(date_value, index, group) {
        if (date_value) {
            date_value = date_value.getFullYear() + '-' + ('0' + (date_value.getMonth() + 1)).slice(-2) + '-' + ('0' + date_value.getDate()).slice(-2) + ' ' + ('0' + date_value.getHours()).slice(-2) + ':' + ('0' + date_value.getMinutes()).slice(-2) + ':00';
        }
        handleDateChange(date_value, index, group);
    }

    function onDateChange(date_value, index, group) {
        if (date_value) {
            date_value = date_value.getFullYear() + '-' + ('0' + (date_value.getMonth() + 1)).slice(-2) + '-' + ('0' + date_value.getDate()).slice(-2);
        }
        handleDateChange(date_value, index, group);
    }

    function triggerSearch() {
        // Validate the search data
        var pristine = new Pristine(document.getElementById("advanced_search_form"), defaultConfig);
        let is_validated = pristine.validate();
        if (!is_validated) {
            return false;
        }

        var newGroupCondition = Object.assign([], advancedGroupSearch);
        newGroupCondition.push({ 'group_condition': groupCondtionCount });
        props.setAdvancedSearch(newGroupCondition);
        props.setSearchState(false);
    }

    /**
     * Handle filter change
     * 
     * @param {object} event 
     */
    function handleFilterChange(event) {
        const value = event.target.value;
        let newState = Object.assign({}, props.filterInfo);
        newState['name'] = value;
        props.setFilterInfo(newState);
    }

    // Save conditions as filter
    function saveAsFilter() {
        // Validate the search data
        var pristine = new Pristine(document.getElementById("advanced_search_form"), defaultConfig);
        let is_validated = pristine.validate();
        if (!is_validated) {
            return false;
        }

        var pristineFilter = new Pristine(document.getElementById("filter_form"), defaultConfig);
        is_validated = pristineFilter.validate();
        if (!is_validated) {
            return false;
        }

        // Store the filter 
        // Show loading icon
        setFilterLoading(true);
        var newGroupCondition = Object.assign([], advancedGroupSearch);
        newGroupCondition.push({ 'group_condition': groupCondtionCount });

        let post_data = {
            filter_info: props.filterInfo,
            advancedSearch: newGroupCondition
        }

        const token = Cookies.get('ba_customer_portal_token');
        var endpoint_url = PortalConfig.crm_url + '/' + props.selected_module + '/filter';
        const config = {
            url: endpoint_url,
            method: 'post',
            data: post_data,
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios(config).then((response) => {
            setFilterLoading(false);
            props.setSearchState(false);
            props.setUserFilters(response.data.user_filters);
            props.setFilterInfo(response.data.filter_info);
            props.setSelectedFilter(response.data.filter_info.id);
            props.setAdvancedSearch(newGroupCondition);

        }).catch((error) => {
            setFilterLoading(false);
            setErrorMessage(error.response.data.message);

            if (error.message == 'Request failed with status code 401') {
                props.updateLoginStatus(false);
            }
        });
    }

    /**
     * Delete filter
     */
    function deleteFilter() {
        var confirmation = window.confirm('Are you sure you want to delete the filter?');
        if (confirmation) {
            setFilterLoading(true);
            const token = Cookies.get('ba_customer_portal_token');
            var endpoint_url = PortalConfig.crm_url + '/' + props.selected_module + '/filter/' + props.filterInfo.id;
            const config = {
                url: endpoint_url,
                method: 'post',
                data: {
                    _METHOD: 'DELETE',
                },
                headers: {
                    Authorization: 'bearer ' + token,
                }
            };

            Axios(config).then((response) => {
                setFilterLoading(false);
                props.setSearchState(false);
                props.setUserFilters(response.data.user_filters);
                props.setFilterInfo({
                    id: '',
                    name: '',
                });

                props.setSelectedFilter('');
                props.setAdvancedSearch({
                    field_name: '',
                    field_condition: 'equal',
                    field_value: '',
                });
            }).catch((error) => {
                setFilterLoading(false);
                setErrorMessage(error.response.data.message);

                if (error.message == 'Request failed with status code 401') {
                    props.updateLoginStatus(false);
                }
            });
        }
    }

    return (
        <Transition.Root show={props.searchState} as={Fragment}>
            <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={props.searchState} onClose={props.setSearchState}>
                <div className="absolute inset-0 overflow-hidden theme-blue">
                    <Dialog.Overlay className="absolute inset-0" />
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-3xl">
                                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="flex-1">
                                        {/* Header */}
                                        <div className="px-4 py-6 bg-gray-50 sm:px-6">
                                            <div className="flex items-start justify-between space-x-3">
                                                <div className="space-y-1">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">Advanced search</Dialog.Title>
                                                </div>
                                                <div className="h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-skin-primary"
                                                        onClick={() => props.setSearchState(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <form id='advanced_search_form'>
                                            {groupCondtionCount.map((groupCondition, groupIndex) => (
                                                <>
                                                    <div class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                                            {groupIndex != 0 && <select name="group_condition" value={groupCondtionCount[groupIndex]} data-id={groupIndex} onChange={handleGroupChange} name={"group_condition_" + groupIndex} > <option>AND</option> <option>OR</option> </select>}
                                                        </label>
                                                        <div className="grid grid-cols-5 gap-4 p-6">
                                                            {advancedGroupSearch[groupIndex] && Object.keys(advancedGroupSearch[groupIndex]).map((index) => {
                                                                if (!props.search_fields || !advancedGroupSearch[groupIndex][index]) {
                                                                    return;
                                                                }

                                                                let element;
                                                                let fieldInfo = {
                                                                    type: 'input-text',
                                                                };

                                                                let field_name = advancedGroupSearch[groupIndex][index]['field_name'];
                                                                if (field_name) {
                                                                    fieldInfo = props.search_fields[field_name];
                                                                }

                                                                if (!fieldInfo) {
                                                                    return;
                                                                }
                                                                let selected_filter_field = ''
                                                                if(fieldInfo.type == 'enum'){
                                                                    selected_filter_field = advancedGroupSearch[groupIndex][index]['field_value'];
                                                                   
                                                                }

                                                                switch (fieldInfo.type) {
                                                                    case 'dynamicenum':
                                                                    case 'enum':
                                                                        element = <select required data-id={index} data-group_id={groupIndex} data-name='field_value' onChange={handleChange} className='border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm' value={advancedGroupSearch[groupIndex][index]['field_value']}>
                                                                            <option value=''>{selected_filter_field} Select </option>
                                                                            {fieldInfo.option && Object.keys(fieldInfo.option).map((value, index) => {
                                                                                return (
                                                                                    <option key={value} value={value}>{fieldInfo.option[value]}</option>
                                                                                )
                                                                            })}
                                                                        </select>;
                                                                        break;
                                                                    case 'bool':
                                                                        element = <input required data-id={index} data-group_id={groupIndex} data-name='field_value' onChange={handleChange} type="checkbox" className="focus:ring-skin-primary h-4 w-4 text-skin-primary border-gray-300 rounded" value={advancedGroupSearch[groupIndex][index]['field_value']} />
                                                                        break;
                                                                    case 'text':
                                                                        element = <textarea required data-id={index} data-group_id={groupIndex} data-name='field_value' onChange={handleChange} className="border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm" value={advancedGroupSearch[groupIndex][index]['field_value']} />
                                                                        break;
                                                                    case 'date':
                                                                        let date_value = '';
                                                                        if (advancedGroupSearch[groupIndex][index]['field_value']) {
                                                                            date_value = new Date(parseISO(advancedGroupSearch[groupIndex][index]['field_value']));
                                                                        }

                                                                        element = <DatePicker selected={date_value} dateFormat="yyyy-MM-dd" className='border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm' name='field_value' onChange={(date) => onDateChange(date, index, groupIndex)} />;
                                                                        break;
                                                                    case 'datetime':
                                                                        let date_time_value = '';
                                                                        if (advancedGroupSearch[groupIndex][index]['field_value']) {
                                                                            date_time_value = new Date(parseISO(advancedGroupSearch[groupIndex][index]['field_value']));
                                                                        }

                                                                        element = <DatePicker showTimeSelect selected={date_time_value} dateFormat="yyyy-MM-dd hh:mm" timeFormat="HH:mm" timeIntervals={15} timeCaption="time" className='border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm' onChange={(date) => onDateTimeChange(date, index, groupIndex)} />;
                                                                        break;
                                                                    default:
                                                                        element = <input required data-id={index} data-group_id={groupIndex} data-name='field_value' onChange={handleChange} type="text" className="border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm" value={advancedGroupSearch[groupIndex][index]['field_value']} />
                                                                        break;
                                                                }

                                                                return (
                                                                    <>
                                                                        <div className="">
                                                                            <select required className='border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm' onChange={handleChange} data-id={index} data-group_id={groupIndex} data-name='field_name' value={advancedGroupSearch[groupIndex][index]['field_name']}>
                                                                                <option value=''>Select field</option>
                                                                                {props.search_fields && Object.keys(props.search_fields).map((field_name) => {
                                                                                    return <option value={field_name} key={field_name}>{props.search_fields[field_name]['name']}</option>;
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                        <div className="">
                                                                            <select required className='border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm' onChange={handleChange} data-id={index} data-group_id={groupIndex} data-name='field_condition' value={advancedGroupSearch[groupIndex][index]['field_condition']}>
                                                                                {Object.keys(conditions).map((condition) => {
                                                                                    if (fieldInfo.type != 'datetime' && (condition == 'greater_than' || condition == 'less_than')) {
                                                                                        return;
                                                                                    }

                                                                                    if ((fieldInfo.type == 'datetime' || fieldInfo.type == 'enum' || fieldInfo.type == 'dynamicenum') && (condition == 'contains')) {
                                                                                        return;
                                                                                    }

                                                                                    return <option value={condition} key={condition}>{conditions[condition]}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                        <div className="">
                                                                            {advancedGroupSearch[groupIndex][index]['field_condition'] != 'empty' && advancedGroupSearch[groupIndex][index]['field_condition'] != 'not_empty' ? element : ''}
                                                                        </div>
                                                                        <div className="">
                                                                            <select className='border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm' onChange={handleChange} data-id={index} data-group_id={groupIndex} data-name='condition_operator' value={advancedGroupSearch[groupIndex][index]['condition_operator']}>
                                                                                <option value=''>Select Operator</option>
                                                                                {Object.keys(conditionOperator).map((condition) => {
                                                                                    return <option value={condition} key={condition}>{conditionOperator[condition]}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                        <div className="">
                                                                            {index > 0 ?
                                                                                <svg onClick={() => deleteCondition(index, groupIndex)} xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                </svg>
                                                                                : ''}
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>

                                                    <div className="p-6 mb-6">
                                                        <button type='button' className="py-2 px-3 float-right border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-100 bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={() => addNewCondition(groupIndex)}>Add condition</button>
                                                    </div>
                                                </>
                                            ))}

                                            <div className="p-6">
                                                <button type='button' className="py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-100 bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={() => addNewGroup()}> Add group </button>
                                            </div>
                                        </form>
                                    </div>

                                    {/* Action buttons */}
                                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                        <div className="space-x-3 flex justify-between">
                                            <div className="gap-4 form-group">
                                                <form id="filter_form">
                                                    <input required type="text" name="filter_name" id="filter_name" value={props.filterInfo.name} placeholder="Filter name" aria-label="Filter name" onChange={handleFilterChange} placeholder="Filter name" className="mr-4 border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm" />
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center py-2 px-4 border-2 shadow-sm text-sm font-medium rounded-md text-skin-primary hover:text-white border-skin-primary hover:bg-skin-primary-dark focus:outline-none"
                                                        onClick={() => saveAsFilter()}
                                                    >
                                                        Save as filter
                                                    </button>
                                                    {props.filterInfo.id ?
                                                        <span className="inline-flex pl-4">
                                                            <svg onClick={() => deleteFilter()} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        </span> : ''}
                                                </form>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="mr-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary"
                                                    onClick={() => props.setSearchState(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-skin-primary hover:bg-skin-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary"
                                                    onClick={() => triggerSearch()}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AdvancedSearch;
